import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Tab, Tabs } from 'react-bootstrap'
import PaginationComponent from './PaginationComponent'
import './AllModelSearch.css'
import { highlightText } from './highlightText'

export default function AllModelSearch() {

    // data
    let [linesData, setLines] = useState([]);
    let [stopsData, setStops] = useState([]);
    let [destsData, setDests] = useState([]);

    //set dest id dict
    const [nameToIdMap, setNameToIdMap] = useState({});

    const linesUrl = "https://backend.nocarnoproblem.net/api/lines/search"
    const stopsUrl = "https://backend.nocarnoproblem.net/api/stops/search"
    const destsUrl = "https://backend.nocarnoproblem.net/api/destinations/search";

    // const local_linesUrl = "http://127.0.0.1:5000/api/lines/search";
    // const local_stopsUrl = "http://127.0.0.1:5000/api/stops/search";
    // const local_destsUrl = "http://127.0.0.1:5000/api/destinations/search";

    const { query } = useParams();

    // calls to api with search query
    const params = useMemo(() => {
        console.log("query: " + query)
        return {
            query: query
        };
    }, [query]);

    console.log(params)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const linesResponse = await axios.get(linesUrl, {
                    headers: { 'Accept': 'application/vnd.api+json' },
                    params,
                });
                console.log(linesResponse.data)
                console.log(linesResponse.request)
                setLines(linesResponse.data);
                const stopsResponse = await axios.get(stopsUrl, {
                    headers: { 'Accept': 'application/vnd.api+json' },
                    params,
                });
                setStops(stopsResponse.data);

                const destsResponse = await axios.get(destsUrl, {
                    headers: { 'Accept': 'application/vnd.api+json' },
                    params,
                });
                const dests = destsResponse.data;
                // Create a dictionary mapping names to IDs
                const nameToIdMap = {};
                dests.forEach((dest, index) => {
                  // Assuming `dest` has a `name` property that is unique
                  nameToIdMap[dest.name] = index;
                });
                // Now you have a dictionary where you can look up IDs by name
                // Store the dictionary in the state if you need to use it in other places
                setNameToIdMap(nameToIdMap);
              
                // Continue with setting the destinations
                setDests(dests);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [linesUrl, stopsUrl, destsUrl, params]);

    const navigateToOtherPage = (navUrl) => {
        navigate(navUrl);
    }
    let navigate = useNavigate();

    // lines cards + pagination 
    const [lines_currentPage, setlines_CurrentPage] = useState(1);

    const lines_itemsPerPage = 20; // You can adjust this number based on how many items you want per page.
    const lines_totalPages = Math.ceil(linesData.length / lines_itemsPerPage);
    const lines_indexOfLastItem = lines_currentPage * lines_itemsPerPage;
    const lines_indexOfFirstItem = lines_indexOfLastItem - lines_itemsPerPage;
    const currentlines = linesData.slice(lines_indexOfFirstItem, lines_indexOfLastItem);

    //Render the current lines we want to display based on pagination 
    let line_cards = currentlines.map((line) => {
        let imgVal = line.highlighted_img;

        return (
            <div>
                <Card onClick={() => navigateToOtherPage("/lines/" + line.global_route_id)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                    <Card.Img variant="top" src={imgVal} />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(line.route_long_name, query)} </strong></Card.Title>
                        <Card.Text className='card-text'>
                            <strong>Global route ID:</strong> {line.global_route_id}<br></br>
                            <strong>Line Type:</strong> {highlightText(line.mode_name, query)} <br></br>
                            <strong>Line Color:</strong> <svg viewBox="0 0 20 20" width="20px" height="20px">
                                <rect width="20" height="20" fill={line.color} /></svg><br></br>
                            <strong>Route Short Name:</strong> {highlightText(line.route_short_name, query)}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })

    // stops cards + pagination
    const [stops_currentPage, setstops_CurrentPage] = useState(1);

    const stops_itemsPerPage = 20; // You can adjust this number based on how many items you want per page.
    const stops_totalPages = Math.ceil(stopsData.length / stops_itemsPerPage);
    const stops_indexOfLastItem = stops_currentPage * stops_itemsPerPage;
    const stops_indexOfFirstItem = stops_indexOfLastItem - stops_itemsPerPage;
    const currentStops = stopsData.slice(stops_indexOfFirstItem, stops_indexOfLastItem);

    //Render the current stops we want to display based on pagination 
    let stop_cards = currentStops.map((stop) => {
        return (
            <div>
                <Card onClick={() => navigateToOtherPage("/stops/" + stop.stop_code)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                <Card.Img variant="top" src={stop.stop_img} />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(stop.stop_name, query)}</strong></Card.Title>
                        <Card.Text className='card-text'>
                            <strong>Global Stop ID:</strong> {highlightText(stop.global_stop_id, query)}<br></br>
                            <strong>Stop Code:</strong> {highlightText(stop.stop_code, query)}<br></br>
                            <strong>Stop Type:</strong> {highlightText(stop.location_type, query)}<br></br>
                            <strong>Wheelchair Boarding:</strong> {highlightText(stop.wheelchair_boarding, query) ? "Available" : "Unavailable"}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })

    // destination cards + pagination
    const [dests_currentPage, setdests_CurrentPage] = useState(1);
    const dests_itemsPerPage = 20; // You can adjust this number based on how many items you want per page.
    const dests_totalPages = Math.ceil(destsData.length / dests_itemsPerPage);
    const dests_indexOfLastItem = dests_currentPage * dests_itemsPerPage;
    const dests_indexOfFirstItem = dests_indexOfLastItem - dests_itemsPerPage;
    const currentDests = destsData.slice(dests_indexOfFirstItem, dests_indexOfLastItem);

    let destination_cards = currentDests.map((dest, index) => {
        let id = index + 1;
        let destinationId = nameToIdMap[dest.name]+1;

        return (
            <div key={id}>
                <Card onClick={() => navigateToOtherPage("/destinations/" + destinationId)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                    <Card.Img variant="top" src={dest.photo_urls} height = "250" />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(dest.name, query)}</strong></Card.Title>
                        <Card.Text className='card-text'>
                            <strong>Business Status:</strong> {highlightText(dest.business_status, query) || "Not Listed"}<br></br>
                            <strong>Phone:</strong> {highlightText(dest.phone, query)}<br></br>
                            <strong>Address:</strong> {highlightText(dest.address, query)}<br></br>
                            <strong>Rating:</strong> {highlightText(dest.rating, query)|| "Not Listed"}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })


    return (
        <>
            <div className="tabs-container">
                <h3>Search Results</h3>
                <Tabs
                    defaultActiveKey="line"
                    className="tabs"
                    justify
                >
                    <Tab eventKey="line" title="Lines">
                        <h1 className="search-title">Lines</h1>
                        <p className="results">{linesData.length} results found.</p>
                        <div className='card-container'>{line_cards}</div>
                        <PaginationComponent
                            currentPage={lines_currentPage}
                            totalPages={lines_totalPages}
                            onChange={(newPage) => setlines_CurrentPage(newPage)} />
                    </Tab>
                    <Tab eventKey="stop" title="Stops">
                        <h1 className="search-title">Stops</h1>
                        <p className="results">{stopsData.length} results found.</p>
                        <div className='card-container'>{stop_cards}</div>
                        <PaginationComponent
                            currentPage={stops_currentPage}
                            totalPages={stops_totalPages}
                            onChange={(newPage) => setstops_CurrentPage(newPage)} />
                    </Tab>
                    <Tab eventKey="dest" title="Destinations">
                        <h1 className="search-title">Destinations</h1>
                        <p className="results">{destsData.length} results found.</p>
                        <div className='card-container'>{destination_cards}</div>
                        <PaginationComponent
                            currentPage={dests_currentPage}
                            totalPages={dests_totalPages}
                            onChange={(newPage) => setdests_CurrentPage(newPage)} />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}