import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function StopLongitudeBarGraph() {
  const [stops, setStops] = useState([]);
  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.nocarnoproblem.net/api/stops");
        const data = await response.json();
        setStops(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (stops.length > 0) {
      const graph = Plot.plot({
        color: { scheme: "YlGnBu", legend: true },
        x: { label: "Longitude", grid: true },
        y: { label: "Latitude", grid: true },
        // title: "Stops Map",
        marks: [
          Plot.dot(stops, {
            x: "stop_lon",
            y: "stop_lat",
            stroke: "stop_code",
            tip: true
          })
        ]
      })

      plotRef.current.append(graph)
      console.log("Graph created:", graph);

      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [stops]);

  return (<div ref={plotRef} className='BarGraph'/>);
}
