
import '../InstanceStyles.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation} from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function InstanceLayout() {
    const location = useLocation();
    const id2 = location.pathname.split("/")[2];

    const baseUrl = `https://backend.nocarnoproblem.net/api/lines/${id2}`;
    console.log("line ID:", id2)
    const [line, lineset] = useState(null);
    const [locationID, locationIDSet] = useState(null);
    const [imgRef, imgRefSet] = useState("");
    const [destName, destNameSet] = useState("None");
    const [stopID, stopIDSet] = useState(628);
    const [stopName, stopNameSet] = useState("None");

    useEffect(() => {
        axios.get(baseUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            lineset(response.data.data.attributes);
            if (
                response.data.data.relationships &&
                response.data.data.relationships.destinations &&
                response.data.data.relationships.destinations.data &&
                response.data.data.relationships.destinations.data.length > 0
            ) {
                locationIDSet(response.data.data.relationships.destinations.data[0].id);
            }
            if (
                response.data.data.relationships &&
                response.data.data.relationships.stops &&
                response.data.data.relationships.stops.data &&
                response.data.data.relationships.stops.data.length > 0
            ) {
                stopIDSet(response.data.data.relationships.stops.data[0].id);
                console.log(stopID)
            }
        });
    }, [baseUrl, stopID]);


    console.log("Location ID:", locationID)

    const locationUrl = `https://backend.nocarnoproblem.net/api/destinations/${locationID}`

    const stopUrl = `https://backend.nocarnoproblem.net/api/stops/${stopID}`;

    useEffect(() => {
        if(locationID){
        axios.get(locationUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            if(response.data.data.attributes.photos && response.data.data.attributes.photos.length > 0) {
            imgRefSet(response.data.data.attributes.photos[0].photo_reference)
            }
            destNameSet(response.data.data.attributes.name);
        });
    }
    }, [locationUrl, locationID]);

    useEffect(() => {
        if(stopID){
        axios.get(stopUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            stopNameSet(response.data.data.attributes.stop_name);
        });
    }
    }, [stopID, stopUrl]);


    if (!line) return null;

    console.log("Image ref: ", imgRef);

    let destPath = `/destinations/${locationID}`
    let stopPath = `/stops/${stopID}`
    return (
        <div>
            <Container>
                <Row style={{ height: 'calc(100vh-35px)' }}>
                    <Col md={6}>
                        <Row className="m-0 p-0 mb-2" style={{ height: 'calc((100vh - 35px - 1rem) / 2)' }}>
                            <Col className="m-0 p-0">
                                {/* Ensure this image source is valid and has data */}
                                <img
                                    src={line.highlighted_img} // Note: `line.image` is used as a placeholder. Adjust if needed.
                                    alt="The Current line"
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 4px)',
                                        objectFit: 'cover',
                                        border: '2px solid black'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="m-0 p-0" style={{ height: '50%' }}>
                            <Col className="m-0 p-0">
                                <iframe
                                    src={line.map_url}
                                    title='Map'
                                    alt="Google Maps location of line"
                                    width="600"
                                    height="450"
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 4px)',
                                        border: '2px solid black'
                                    }}
                                    allowFullScreen={true}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="card custom-card" style={{ width: '100%' }}>
                            <div className="card-body">
                                <h1 className="display-2"><strong>{line.route_long_name}</strong></h1>
                                <ul className="list-group">
                                    <li className="list-group-item custom-item"><strong>Global route ID:</strong> {line.global_route_id}</li>
                                    <li className="list-group-item custom-item"><strong>Line Type:</strong> {line.mode_name}</li>
                                    <li className="list-group-item custom-item"><strong>Line Color</strong> <svg viewBox="0 0 20 20" width="20px" height="20px">
                                <rect width="20" height="20" fill={line.color} /></svg><br></br></li>
                                    <li className="list-group-item custom-item"><strong>Route Short Name:</strong> {line.route_short_name}</li>
                                    <li className="list-group-item custom-item"><strong>Destination:</strong> <a class="btn btn-primary" href={destPath} role="button"> {destName}</a></li>
                                    <li className="list-group-item custom-item"><strong>Stop:</strong> <a class="btn btn-primary" href={stopPath} role="button"> {stopName}</a></li>


                                    {/* <li className="list-group-item custom-item"><strong>Route:</strong> <Link to={`https://www.nocarnoproblem.net/routes/${line.route}`} className="btn btn-primary">40 Acres</Link></li> */}
                                    {/* Note: Adjust this as necessary since `line.route` and the button text '40 Acres' are placeholders */}
                                    {/* <li className="list-group-item custom-item"><strong>Point of Interest:</strong> <Link to={`https://www.nocarnoproblem.net/destinations/${line.pointInterest}`} className="btn btn-primary">Kerbey Lane Cafe</Link></li> */}
                                    {/* Note: Adjust this as necessary since `line.pointInterest` and the button text 'Kerbey Lane Cafe' are placeholders */}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default InstanceLayout;

