import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function WheelchairStopMap() {
  const [stops, setStops] = useState([]);

  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.austin-wheels.me/transport");
        const data = await response.json();
        setStops(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching stop data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (stops.length > 0) {
      const graph = Plot.plot({
        x: { grid: true },
        y: { grid: true },
        color: { legend: false, scheme: "Category10", reverse: true },
        // title: "Wheelchair Stops Map",
        marks: [
          Plot.dot(stops, {
            x: "long",
            y: "lat",
            stroke: "city",
            tip: true,
            fill: "city"
          })
        ]
      })

      plotRef.current.append(graph)

      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [stops]);

  return (<div ref={plotRef} className='BarGraph'/>);
}
