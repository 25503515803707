import React, { useState, useEffect } from 'react'
import axios from "axios";
import './Stops.css'
import './Models.css'
import { Card } from 'react-bootstrap'
import ModelOptions from './ModelOptions'
import { useNavigate } from 'react-router-dom';
import PaginationComponent from './PaginationComponent'
import { highlightText } from './highlightText'

export default function Destinations() {
    //pagination 
    const [currentPage, setCurrentPage] = useState(1);

    //separate url for front end navigation?
    let navigate = useNavigate();
    
    // api info
    const baseUrl = "https://backend.nocarnoproblem.net/api/destinations";
    
    // set  data
    const [dests, destsSet] = useState([]);

    //set dest id dict
    const [nameToIdMap, setNameToIdMap] = useState({});
    
    // search term state variable
    const [searchTerm, setSearchTerm] = useState(null)
    // state variables for sorts and filters
    const [currentSort, setCurrentSort] = useState(null)
    const [sortReversed, setSortReversed] = useState(null)
    const [filters, setFilters] = useState([
        {displayPhrase: 'Business Status', dataPhrase: 'business_statuses', values: [
            {displayPhrase: 'Operational', dataPhrase: 'OPERATIONAL', checked: true}, 
            {displayPhrase: 'Closed Temporarily', dataPhrase: 'CLOSED_TEMPORARILY', checked: true}
        ]}
    ])
    const sorts = [
        {displayPhrase: 'Name (A-Z)', dataPhrase: 'name', sortReversed: 0}, 
        {displayPhrase: 'Latitude (North to South)', dataPhrase: 'latitude', sortReversed: 1},
        {displayPhrase: 'Longitude (East to West)', dataPhrase: 'longitude', sortReversed: 1},
        {displayPhrase: 'Ratings (High to Low)', dataPhrase: 'ratings', sortReversed: 1}
    ]

    // makes api call every time sorts and filters are changed
    useEffect(() => {
        if (searchTerm != null) {
            const params = {
                query: searchTerm
            }
            axios.get(baseUrl + "/search",  { 
                headers: {
                'Accept': 'application/vnd.api+json'
                }, params}) 
                .then((response) => {
                    console.log(response)
                    destsSet(response.data);
                }).catch(error => {
                    console.error("There was an error fetching the data when searching:", error);
                });
        } else {
        const params = {
            sort_by: currentSort,
            sort_reversed: + sortReversed
        };

        filters.forEach((filterGroup) => {
            var possibleValues = filterGroup.values
            var checkedPossibleValues = possibleValues.filter((possibleValue) => possibleValue.checked === true)
            var dataPhrasesForQuery = checkedPossibleValues.map((possibleValue) => possibleValue.dataPhrase)
            params[filterGroup.dataPhrase] = dataPhrasesForQuery.join(",")
        })

        axios.get(baseUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            },
            params
        })
            .then((response) => {
                const dests = response.data;
                // Create a dictionary mapping names to IDs
                const nameToIdMap = {};
                dests.forEach((dest, index) => {
                  // Assuming `dest` has a `name` property that is unique
                  nameToIdMap[dest.name] = index;
                });
                // Now you have a dictionary where you can look up IDs by name
                // Store the dictionary in the state if you need to use it in other places
                setNameToIdMap(nameToIdMap);
              
                // Continue with setting the destinations
                destsSet(dests);
              })
              .catch((error) => {
                // Handle any errors here
                console.error('There was an error fetching the destinations:', error);
              });
        }
    }, [baseUrl, currentSort, sortReversed, filters, searchTerm]);
    if (!dests) {
        return null;
    }

    // pagination
    const itemsPerPage = 20; // You can adjust this number based on how many items you want per page.
    const totalPages = Math.ceil(dests.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentDests = dests.slice(indexOfFirstItem, indexOfLastItem);

    // navigate to base url + id
    const navigateToOtherPage = (navUrl) => {
        navigate("" + navUrl);
    }
    let destination_cards = currentDests.map((dest, index) => {
        let id = index + 1;
        let destinationId = nameToIdMap[dest.name] + 1;
        
        return (
            <div key = {id}>
                <Card onClick={() => navigateToOtherPage(destinationId)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                    <Card.Img variant="top" src={dest.photo_urls} height = "250" />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(dest.name, searchTerm)}</strong></Card.Title>
                        <Card.Text className='card-text'>
                            <strong>Business Status:</strong> {highlightText(dest.business_status, searchTerm) || "Not Listed"}<br></br>
                            <strong>Phone:</strong> {highlightText(dest.phone, searchTerm)}<br></br>
                            <strong>Address:</strong> {highlightText(dest.address, searchTerm)}<br></br>
                            <strong>Rating:</strong> {highlightText(dest.rating, searchTerm)|| "Not Listed"}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })

    return (
        <div>
            <h1 class='page-title'>Destinations</h1>
            <ModelOptions
                sorts={sorts}
                currentSort={currentSort}
                setCurrentSort={setCurrentSort}
                setSortReversed={setSortReversed}
                setSearchTerm={setSearchTerm}
                filters={filters} 
                setFilters={setFilters}               
                data={dests}
            ></ModelOptions>
            <div className='card-container'>{destination_cards}</div>
            <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={(newPage) => setCurrentPage(newPage)} />
        </div>
    )
}