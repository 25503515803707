import React from "react"
import './Visuals.css'
import DestinationMap from "./Visualizations/DestinationMap.js";
import StopMap from "./Visualizations/StopMap.js";
import LineGraph from "./Visualizations/LineGraph.js";

export default function OurVisuals() {
    return (
        <div className="our-visuals">
            <h1>Visualizations</h1>

            {/* <h3>Longitude of Destinations</h3>
            <div className="graph">
              <DestinationLongitudeBarGraph/>
            </div>
            <h3>Latitude of Destinations</h3>
            <div className="graph">
              <DestinationLatitudeBarGraph/>
            </div> */}

            <h3>Map of Destinations</h3>
            <div className="graph">
              <DestinationMap/>
            </div>

            {/* <h3>Longitude of Stops</h3>
            <div className="graph">
              <StopLongitudeBarGraph/>
            </div>
            <h3>Latitude of Stops</h3>
            <div className="graph">
              <StopLatitudeBarGraph/>
            </div> */}

            <h3>Map of Stops</h3>
            <div className="graph">
              <StopMap/>
            </div>

            <h3>Different Types of Lines</h3>
            <div className="graph">
              <LineGraph/>
            </div>
        </div>
    )
}