import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./DestinationLatitudeBarGraph.css";

export default function DestinationMap() {
  const [destinations, setDestinations] = useState([]);
  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.nocarnoproblem.net/api/destinations");
        const data = await response.json();
        setDestinations(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (destinations.length > 0) {
      const graph = Plot.plot({
        color: { legend: true, scheme: "YlGnBu", reverse: true },
        // title: "Destination Map",
        x: { label: "Longitude", grid: true },
        y: { label: "Latitude", grid: true },
        marks: [
          Plot.dot(destinations, {
            x: "location_lon",
            y: "location_lat",
            stroke: "business_status",
            tip: true
          })
        ]
      })

      plotRef.current.append(graph)
      console.log("Graph created:", graph);

      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [destinations]);

  return (<div ref={plotRef} className='BarGraph'/>);
}
