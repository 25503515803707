import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/NavbarComponent';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './components/Home'
import Lines from './components/Lines'
import Stops from './components/Stops'
import Destinations from './components/Destinations'
import About from './components/About'
//Instance Templates
import StopInstanceTemplate from './components/Instances/stops/StopInstanceTemplate'
import DestinationInstanceTemplate from './components/Instances/destinations/DestinationInstanceTemplate'
import LineInstanceTemplate from './components/Instances/lines/LineInstanceTemplate'
import AllModelSearch from './components/AllModelSearch';

// D3 Visuals
import OurVisuals from './components/OurVisuals'
import ProviderVisuals from './components/ProviderVisuals'

const router = createBrowserRouter([
  //Model, Home, About routing
  {
      path: "/",
      element: <Home/>
  },
  {
      path: "/lines",
      element: <Lines/>
  },
  {
      path: "/stops",
      element: <Stops/>
  },
  {
      path: "/destinations",
      element: <Destinations/>
  },
  {
      path: "/about",
      element: <About/>
  },

  // general search that support query path
  {
    path: "/allmodelsearch/:query",
    element: <AllModelSearch/>
  },

  //InstanceRouting
  {
    path: "/stops/:id",
    element: <StopInstanceTemplate/>
  },
  {
    path: "/destinations/:id",
    element: <DestinationInstanceTemplate/>
  },
  {
    path: "/lines/:id",
    element: <LineInstanceTemplate/>
  },
  {
    path:"/ourvisuals",
    element: <OurVisuals/>
  },
  {
    path:"/providervisuals",
    element: <ProviderVisuals/>
  },


])

function App() {

  return (
    <div className="App">
        <NavbarComponent/>
        <RouterProvider router={router}></RouterProvider> 
    </div>
  );
}

export default App;
