import React, { useState, useEffect } from 'react'
import axios from "axios";
import './Stops.css'
import './Models.css'
import { Card } from 'react-bootstrap'
import ModelOptions from './ModelOptions'
import { useNavigate } from 'react-router-dom';
import PaginationComponent from './PaginationComponent'
import { highlightText } from './highlightText'

export default function Stops() {
    const frontEndUrl = "";
    const [currentPage, setCurrentPage] = useState(1);
    let navigate = useNavigate();
    const baseUrl = "https://backend.nocarnoproblem.net/api/stops";
    
    // data
    const [stops, stopSet] = useState([]);
    
    // search term state variable
    const [searchTerm, setSearchTerm] = useState(null)
    // sorting info
    const [currentSort, setCurrentSort] = useState(null)
    const [sortReversed, setSortReversed] = useState(null)
    const sorts = [
        {displayPhrase: 'Name (A-Z)', dataPhrase: 'name', sortReversed: 0}, 
        {displayPhrase: 'Stop ID (Inc)', dataPhrase: 'id', sortReversed: 0},
        {displayPhrase: 'Latitude (North to South)', dataPhrase: 'latitude', sortReversed: 1},
        {displayPhrase: 'Longitude (East to West)', dataPhrase: 'longitude', sortReversed: 1},
        {displayPhrase: 'Number of Lines (Desc)', dataPhrase: 'num_lines', sortReversed: 1}
    ]

    // makes new api call every time sorting options are changed
    useEffect(() => {
        if (searchTerm != null) {
            const params = {
                query: searchTerm
            }
            axios.get(baseUrl + "/search",  { 
                headers: {
                'Accept': 'application/vnd.api+json'
                }, params}) 
                .then((response) => {
                    stopSet(response.data);
                    console.log("Stop set");
                }).catch(error => {
                    console.error("There was an error fetching the data when searching:", error);
                });
        } else {
        const params = {
            sort_by: currentSort,
            sort_reversed: + sortReversed
        };
        axios.get(baseUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            },
            params
        })
            .then((response) => {
                stopSet(response.data);
                console.log("Stop set");
            })
            .catch(error => {
                console.error("There was an error fetching the data:", error);
            });
        }
    }, [currentSort, sortReversed, searchTerm]);

    if (!stops) {
        return null;
    }

    //pagination
    const itemsPerPage = 20; // You can adjust this number based on how many items you want per page.
    const totalPages = Math.ceil(stops.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentStops = stops.slice(indexOfFirstItem, indexOfLastItem);

    //navigate to base url + id
    const navigateToOtherPage = (navUrl) => {
        navigate(navUrl);
    }
    //Render the current stops we want to display based on pagination 
    let stop_cards = currentStops.map((stop) => {
        return (
            <div>
                <Card onClick={() => navigateToOtherPage(frontEndUrl + stop.stop_code)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                    <Card.Img variant="top" src={stop.stop_img} />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(stop.stop_name, searchTerm)}</strong></Card.Title>
                        <Card.Text className='card-text'>
                            <strong>Global Stop ID:</strong> {highlightText(stop.global_stop_id, searchTerm)}<br></br>
                            <strong>Stop Code:</strong> {highlightText(stop.stop_code, searchTerm)}<br></br>
                            <strong>Stop Type:</strong> {highlightText(stop.location_type, searchTerm)}<br></br>
                            <strong>Wheelchair Boarding:</strong> {highlightText(stop.wheelchair_boarding, searchTerm) ? "Available" : "Unavailable"}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })

    return (
        <div>
            <h1 className='page-title'>Stops</h1>
            <ModelOptions
                sorts={sorts}                
                currentSort={currentSort}
                setCurrentSort={setCurrentSort}
                setSortReversed={setSortReversed}
                setSearchTerm={setSearchTerm}
                data={stops}
            ></ModelOptions>
            <div className='card-container'>{stop_cards}</div>
            <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={(newPage) => setCurrentPage(newPage)} />
        </div>
    )
}