import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function StopMap() {
  const [lines, setLines] = useState([]);


  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
    try {
        const response = await fetch("https://backend.nocarnoproblem.net/api/lines");
        const data = await response.json();
        setLines(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching line data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (lines.length > 0) {
      const graph = Plot.plot({
        color: { scheme: "YlGnBu", legend: true },
        marks: [
          Plot.barY(
            lines,
            Plot.groupX(
              { y: "count" },
              { x: "route_type", fill: "route_type", tip: true }
            )
          ),
          Plot.ruleY([0])
        ]
      })

      plotRef.current.append(graph)
      console.log("Graph created:", graph);

      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [lines]);

  return (<div ref={plotRef} className='BarGraph'/>);
}
