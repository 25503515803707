import React from 'react'
import { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import search from "../assets/search_icon.svg.png"
import './ModelOptions.css'

export default function ModelOptions (props) {
    
    // state variables for sort/filter modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);

    // handles changes to sort 'radio' buttons
    const handleSortChange = (event) => {
        
        // allows parent page to make correct api call
        props.setCurrentSort(event.target.value.slice(0, -1))
        props.setSortReversed(Boolean(parseInt(event.target.value.slice(-1))))
    }

    // handles changes to filter checkboxes
    const handleFilterChange = (possibleValue, event) => {
        
        // create a new array with updated checked value
        const updatedFilters = props.filters.map((filter) => ({
            ...filter,
            values: filter.values.map((value) =>
            value.dataPhrase === possibleValue.dataPhrase
                ? { ...value, checked: !value.checked }
                : value
            ),
        }));
    
        // set parent filters array to updatedFilters so parent can make proper api call
        props.setFilters(updatedFilters);
    }

    // handles searching
    let [value, setValue] = useState("");
    const saveSearch = (e) => {
        setValue(e.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleSearch = () => {
        console.log("Search Term to Set:", value);
        props.setSearchTerm(value)
        console.log("working")
    }

    return (
        <Form inline>
            <div id="filter-and-sort" data-testid="search">   
                <div className="search-bar-wrapper">
                    <div>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            value={value}
                            onChange={saveSearch}
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div>
                        <Button type="button" id="submit-button" onClick={handleSearch}>
                            <img src={search} style={{height: '20px'}} alt='magnifying glass'/>
                        </Button>
                    </div>
                </div>        
                <div>
                    <Button id="sort-and-filter-button" varient="primary" onClick={handleOpenModal}>
                        Sort/Filter
                    </Button>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sorting and Filtering Options</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <div className='row' id='sort-and-filter'>
                                    <div className='col-6'>
                                        <h4>Sort</h4>
                                        <div>
                                            {props.sorts.map((sort) => (
                                                <Form.Check 
                                                    type="radio" 
                                                    label={sort.displayPhrase} 
                                                    name="sorts" 
                                                    value={sort.dataPhrase + sort.sortReversed} 
                                                    onChange={handleSortChange} 
                                                    defaultChecked={sort.dataPhrase === props.currentSort}>
                                                </Form.Check>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <h4>Filter</h4>
                                        <div>
                                            {props.filters ? props.filters.map((filter) => {
                                                return (
                                                    <div>
                                                        <h6>{filter.displayPhrase}</h6>
                                                        {filter.values.map((possibleValue) => {
                                                            return(
                                                                <Form.Check 
                                                                    type="checkbox" 
                                                                    label={possibleValue.displayPhrase} 
                                                                    value={possibleValue.dataPhrase} 
                                                                    name="filters" 
                                                                    onChange={(event) => handleFilterChange(possibleValue, event)} 
                                                                    checked={possibleValue.checked}>                                                        
                                                                </Form.Check>
                                                            )
                                                        })}
                                                        <br></br>
                                                    </div>
                                                )
                                            // shown if there are no filters
                                            }) : <p>N/A</p>} 
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="total">
                    <p>{props.data.length} results found.</p>
                </div>
            </div>
        </Form>
    )
}