
import '../InstanceStyles.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation} from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function InstanceLayout() {
    const location = useLocation();
    const id2 = location.pathname.split("/")[2];

    const baseUrl = `https://backend.nocarnoproblem.net/api/stops/${id2}`;
    console.log("Stop ID:", id2)
    const [stop, stopSet] = useState(null);
    const [locationID, locationIDSet] = useState(null);
    const [lineID, lineIDSet] = useState(null);
    const [imgRef, imgRefSet] = useState("");
    const [destName, destNameSet] = useState("None");
    const [lineName, lineNameSet] = useState("None");
    useEffect(() => {
        axios.get(baseUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            stopSet(response.data.data.attributes);
            if (
                response.data.data.relationships &&
                response.data.data.relationships.destinations &&
                response.data.data.relationships.destinations.data &&
                response.data.data.relationships.destinations.data.length > 0
            ) {
                locationIDSet(response.data.data.relationships.destinations.data[0].id);
            }
            if (
                response.data.data.relationships &&
                response.data.data.relationships.lines &&
                response.data.data.relationships.lines.data &&
                response.data.data.relationships.lines.data.length > 0
            ) {
                lineIDSet(response.data.data.relationships.lines.data[0].id);
            }
        });
    }, [baseUrl]);


    console.log("Location ID:", locationID)

    const locationUrl = `https://backend.nocarnoproblem.net/api/destinations/${locationID}`
    const lineUrl = `https://backend.nocarnoproblem.net/api/lines/${lineID}`

    useEffect(() => {
        if(locationID){
        axios.get(locationUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            if(response.data.data.attributes.photos && response.data.data.attributes.photos.length > 0) {
            imgRefSet(response.data.data.attributes.photos[0].photo_reference)
            }
            destNameSet(response.data.data.attributes.name);
        });
    }
    }, [locationUrl, locationID]);

    useEffect(() => {
        if(lineID){
        axios.get(lineUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            lineNameSet(response.data.data.attributes.route_long_name);
        });
    }
    }, [locationUrl, locationID, lineID, lineUrl]);

    if (!stop) return null;
    console.log("Image ref: ", imgRef);

    let destPath = `/destinations/${locationID}`
    let linePath = `/lines/${lineID}`
    return (
        <div>
            <Container>
                <Row style={{ height: 'calc(100vh-35px)' }}>
                    <Col md={6}>
                        <Row className="m-0 p-0 mb-2" style={{ height: 'calc((100vh - 35px - 1rem) / 2)' }}>
                            <Col className="m-0 p-0">
                                {/* Ensure this image source is valid and has data */}
                                <img
                                    src={stop.stop_img} // Note: `stop.image` is used as a placeholder. Adjust if needed.
                                    alt="The Current Stop"
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 4px)',
                                        objectFit: 'cover',
                                        border: '2px solid black'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="m-0 p-0" style={{ height: '50%' }}>
                            <Col className="m-0 p-0">
                                <iframe
                                    src={stop.map_url}
                                    title='Map'
                                    alt="Google Maps location of stop"
                                    width="600"
                                    height="450"
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 4px)',
                                        border: '2px solid black'
                                    }}
                                    allowFullScreen={true}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="card custom-card" style={{ width: '100%' }}>
                            <div className="card-body">
                                <h1 className="display-2"><strong>{stop.stop_name}</strong></h1>
                                <ul className="list-group">
                                    <li className="list-group-item custom-item"><strong>Stop Code:</strong> {stop.stop_code}</li>
                                    <li className="list-group-item custom-item"><strong>Stop ID:</strong> {stop.global_stop_id}</li>
                                    <li className="list-group-item custom-item"><strong>Stop Type:</strong> {stop.location_type}</li>
                                    <li className="list-group-item custom-item"><strong>Wheelchair Boarding:</strong> {stop.wheelchair_boarding ? "Available" : "Unavailable"}</li>
                                    <li className="list-group-item custom-item"><strong>Destination:</strong> <a class="btn btn-primary" href={destPath} role="button"> {destName}</a></li>
                                    <li className="list-group-item custom-item"><strong>Line:</strong> <a class="btn btn-primary" href={linePath} role="button"> {lineName}</a></li>



                                    {/* <li className="list-group-item custom-item"><strong>Route:</strong> <Link to={`https://www.nocarnoproblem.net/routes/${stop.route}`} className="btn btn-primary">40 Acres</Link></li> */}
                                    {/* Note: Adjust this as necessary since `stop.route` and the button text '40 Acres' are placeholders */}
                                    {/* <li className="list-group-item custom-item"><strong>Point of Interest:</strong> <Link to={`https://www.nocarnoproblem.net/destinations/${stop.pointInterest}`} className="btn btn-primary">Kerbey Lane Cafe</Link></li> */}
                                    {/* Note: Adjust this as necessary since `stop.pointInterest` and the button text 'Kerbey Lane Cafe' are placeholders */}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default InstanceLayout;

