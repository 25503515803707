import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import bus from '../assets/bus.gif';
import austin from '../assets/austin-skyline.jpg';
import './Home.css'

export default class Home extends Component {
    render() {
        return (
            <div>
                <img id='busAnimation' src={bus} alt="animation of a CapMetro bus moving" />
                <div id='textContainer'>
                    <h1 id='blurb'>Helping Austinites navigate the city with ease — no car needed.</h1>
                </div>
                <img id='austinSkyline' src={austin} alt="buildings on the Austin, TX skyline"></img>
                <h3 id='cardTitle'>Start Exploring!</h3>
                <div className='row'>
                    <div className='col-4'>
                        <a href='/lines'>
                            <Card className='h-100 card'>
                                <Card.Body>
                                    <Card.Title className='title'>Lines</Card.Title>
                                    <Card.Text className='text' style={{textAlign: 'center'}}>
                                        Find a line to take you where you need to go.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-4'>
                        <a href='/stops'>
                            <Card className='h-100 card'>
                                <Card.Body>
                                    <Card.Title className='title'>Stops</Card.Title>
                                    <Card.Text className='text' style={{textAlign: 'center'}}>
                                        Locate stops near you.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-4'>
                        <a href='/destinations'>
                            <Card className='h-100 card'>
                                <Card.Body>
                                    <Card.Title className='title'>Destinations</Card.Title>
                                    <Card.Text className='text' style={{textAlign: 'center'}}>
                                        Explore the city.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}