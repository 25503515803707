import React from 'react';
import { Pagination } from 'react-bootstrap';
import './PaginationComponent.css';

export default function PaginationComponent({ currentPage, totalPages, onChange }) {
    const pageNeighbors = 2; // how many pages to show around the current page on each side
    const totalPagesShown = (pageNeighbors * 2) + 1 // accounts for current page and two neighbors on either side

    // handles click of next page button
    const handleNextPage = () => {
        onChange(currentPage + 1);
    }

    // handles click of previous page button
    const handlePreviousPage = () => {
        onChange(currentPage - 1);
    }

    // handles click of page number
    const handleChangePage = (number) => {
        onChange(number);
    }

    const fetchPageNumbers = () => {

        // calculating the block of numbers around the current page
        let blockStart = Math.max(1, currentPage - pageNeighbors);
        let blockEnd = Math.min(totalPages, currentPage + pageNeighbors);

        console.log(blockStart)
        console.log(blockEnd)
        console.log((blockEnd - blockStart) + 1)
        console.log(totalPagesShown)

        if (((blockEnd - blockStart)) + 1 !== totalPagesShown) {
            if (blockStart === 1) {
                blockEnd = Math.min(totalPages, totalPagesShown);
            } else if (blockEnd === totalPages) {
                blockStart = Math.max(1, totalPages - totalPagesShown + 1);
            }
        }

        const range = Array.from({ length: (blockEnd - blockStart) + 1 }, (_, i) => blockStart + i);
        return range;
    };

    // getting page numbers to show
    const pages = fetchPageNumbers();
    
    // adding pages and ellipses to items
    let items = [];
    pages.forEach((page) => {
        items.push(
            <Pagination.Item key={page} active={page === currentPage} onClick={() => handleChangePage(page)}>
                {page}
            </Pagination.Item>
        );
    });

    return (
        <div className="pagination-container">
            <Pagination>
                <Pagination.First onClick={() => handleChangePage(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={handlePreviousPage} disabled={currentPage === 1} />
                {items}
                <Pagination.Next onClick={handleNextPage} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handleChangePage(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
}
