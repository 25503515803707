import React, { useState, useEffect } from 'react'
import axios from "axios";
import './Stops.css'
import './Models.css'
import { Card } from 'react-bootstrap'
import ModelOptions from './ModelOptions'
import { useNavigate } from 'react-router-dom';
import PaginationComponent from './PaginationComponent'
import { highlightText } from './highlightText'

export default function Lines() {
    const frontEndUrl = "";

    //pagination 
    const [currentPage, setCurrentPage] = useState(1);

    let navigate = useNavigate();
    const baseUrl = "https://backend.nocarnoproblem.net/api/lines";
    
    // data
    const [lines, setLines] = useState([]);
    // search term state variable
    const [searchTerm, setSearchTerm] = useState(null)
    // sorting and filtering info
    const [currentSort, setCurrentSort] = useState(null)
    const [sortReversed, setSortReversed] = useState(null)
    const sorts = [
        {displayPhrase: 'Name (A-Z)', dataPhrase: 'name', sortReversed: 0}, 
        {displayPhrase: 'Stop Code (Inc)', dataPhrase: 'code', sortReversed: 0},
    ]
    const [filters, setFilters] = useState(
        [
            {displayPhrase: 'Frequency', dataPhrase: 'high_frequency', values: [
                {displayPhrase: 'High-Frequency', dataPhrase: 1, checked: true}, 
                {displayPhrase: 'non-High-Frequency', dataPhrase: 0, checked: true}
            ]}, 
            {displayPhrase: 'Route Type', dataPhrase: 'types', values: [
                {displayPhrase: 'Crosstown', dataPhrase: 'Crosstown', checked: true}, 
                {displayPhrase: 'Feeder', dataPhrase: 'Feeder', checked: true}, 
                {displayPhrase: 'Flyer', dataPhrase: 'Flyer', checked: true}, 
                {displayPhrase: 'Local', dataPhrase: 'Local', checked: true},
                {displayPhrase: 'Rapid', dataPhrase: 'Rapid', checked: true}, 
                {displayPhrase: 'Special Service', dataPhrase: 'Special Service', checked: true}, 
                {displayPhrase: 'UT Shuttle', dataPhrase: 'UT Shuttle', checked: true}, 
                {displayPhrase: 'None', dataPhrase: 'None', checked: true}
            ]},
            {displayPhrase: 'Color', dataPhrase: 'colors', values: [
                {displayPhrase: '#004A97', dataPhrase: '#004A97', checked: true}, 
                {displayPhrase: '#555555', dataPhrase: '#555555', checked: true}, 
                {displayPhrase: '#E2231A', dataPhrase: '#E2231A', checked: true}
            ]},
            {displayPhrase: 'Mode', dataPhrase: 'modes', values: [
                {displayPhrase: 'Bus', dataPhrase: 'bus', checked: true}, 
                {displayPhrase: 'Regional Train', dataPhrase: 'regionalTrain', checked: true}
            ]}
        ]
    )

    // make api call every time sorts and filters are changed
    useEffect(() => {
        if (searchTerm != null) {
            const params = {
                query: searchTerm
            }
            axios.get(baseUrl + "/search",  { 
                headers: {
                'Accept': 'application/vnd.api+json'
                }, params}) 
                .then((response) => {
                    console.log(response)
                    setLines(response.data);
                }).catch(error => {
                    console.error("There was an error fetching the data when searching:", error);
                });
        } else {
            const params = {
                sort_by: currentSort,
                sort_reversed: + sortReversed
            };
    
            filters.forEach((filterGroup) => {
                var possibleValues = filterGroup.values
                var checkedPossibleValues = possibleValues.filter((possibleValue) => possibleValue.checked === true)
                var dataPhrasesForQuery = checkedPossibleValues.map((possibleValue) => possibleValue.dataPhrase)
                params[filterGroup.dataPhrase] = dataPhrasesForQuery.join(",")
            })
    
            axios.get(baseUrl, { params })
                .then((response) => {
                    // Save the line data
                    console.log(response)
                    setLines(response.data);
                })
                .catch(error => {
                    console.error("There was an error fetching the data:", error);
                });
        }
    }, [currentSort, sortReversed, searchTerm,filters]);

    if (!lines) {
        return null;
    }

    // pagination
    const itemsPerPage = 20; 
    const totalPages = Math.ceil(lines.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentlines = lines.slice(indexOfFirstItem, indexOfLastItem);

    // navigate to base url + id
    const navigateToOtherPage = (navUrl) => {
        navigate(navUrl);
    }

    // render the current lines we want to display based on pagination 
    let line_cards = currentlines.map((line) => {
        let imgVal = line.highlighted_img;

        return (
            <div>
                <Card onClick={() => navigateToOtherPage(frontEndUrl + line.global_route_id)} className="h-100" style={{ width: '18rem', cursor: 'pointer' }}>
                    <Card.Img variant="top" src={imgVal} />
                    <Card.Body>
                        <Card.Title><strong>{highlightText(line.route_long_name, searchTerm)} </strong></Card.Title>
                        <Card.Text className='card-text'>
                           <strong>Global route ID:</strong> {line.global_route_id}<br></br>
                           <strong>Line Type:</strong> {highlightText(line.mode_name, searchTerm)} <br></br>
                           <strong>Line Color:</strong> <svg viewBox="0 0 20 20" width="20px" height="20px">
                                <rect width="20" height="20" fill={line.color} /></svg><br></br>
                            <strong>Route Short Name:</strong> {highlightText(line.route_short_name, searchTerm)}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    })

    return (
        <div>
            <h1 className='page-title'>Lines</h1>
            <ModelOptions
                sorts={sorts}
                currentSort={currentSort}
                setCurrentSort={setCurrentSort}
                setSortReversed={setSortReversed}
                setSearchTerm={setSearchTerm}
                filters={filters}
                setFilters={setFilters}
                data={lines}
            ></ModelOptions>
            <div className='card-container'>{line_cards}</div>
            <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={(newPage) => setCurrentPage(newPage)} />
        </div>
    )
}