import React, { useState } from 'react'
import { Navbar, Nav, Container, Form, Button } from 'react-bootstrap'
import './NavbarComponent.css'
import logo from "../assets/No Car No Problem.png"
import search from "../assets/search_icon.svg.png"

export default function NavbarComponent(props) {
    const pathname = window.location.pathname

    const [searchTerm, setSearchTerm] = useState(null)

    const saveSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(null);
        }
    };

    const handleSearch = (event) => {
        if (event !== null) {
            event.preventDefault();
        }

        if (searchTerm != null) {
            // navigate to overall search page
            window.location.href = `/allmodelsearch/${encodeURIComponent(searchTerm)}`;
        }
    }

    return (
        <div>
            <Navbar fixed='top' expand="lg" className="navbar">
            <Container>
                <Navbar.Brand href="/">
                    <img id= "logo" src = {logo} style={{height:'35px'}} alt="Bus Icon with No Car. No Problem. on left"></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link className={(pathname === "/lines" ? 'current' : '') + 'link'} href="/lines">Lines</Nav.Link>
                    <Nav.Link className={(pathname === "/stops" ? 'current' : '') + 'link'} href="/stops">Stops</Nav.Link>
                    <Nav.Link className={(pathname === "/destinations" ? 'current' : '') + 'link'} href="/destinations">Destinations</Nav.Link>
                    <Nav.Link className={(pathname === "/about" ? 'current' : '') + 'link'} href="/about">About</Nav.Link>
                    <Nav.Link className={(pathname === "/ourvisuals" ? 'current' : '') + 'link'} href="/ourvisuals">Visualizations</Nav.Link>
                    <Nav.Link className={(pathname === "/providervisuals" ? 'current' : '') + 'link'} href="/providervisuals">Provider Visualizations</Nav.Link>
                </Nav>
                </Navbar.Collapse>
                <div className="search-bar-wrapper">
                <div>
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={saveSearch}
                        onKeyDown={handleKeyPress}
                    />
                </div>
                <div>
                    <Button className="search-button" type="button" id="button" onClick={handleSearch}>
                        <img src={search} style={{height: '20px'}} alt='magnifying glass'/>
                    </Button>
                </div>
                </div>
            </Container>
            </Navbar>
        </div>
    )
}
