import React from "react"
import './Visuals.css'
import WheelchairStopMap from "./Visualizations/WheelchairStopMap.js";
import ZipCodeGraph from "./Visualizations/ZipCodeGraph.js";
import ChartPhoto from "../assets/chart.png"


export default function ProviderVisuals() {

    return (
        <div className="provider-visuals">

            <h1>Provider Visualizations</h1>

            <h3>Map of Stops</h3>
            <div className="graph">
            <WheelchairStopMap/>
            </div>

            <h3>Number of Locations and Stops for each Zip Code</h3>
            <div className="graph">
            <ZipCodeGraph/>
            </div>

            <h3>Number of Each Type of Location</h3>
            <div className="graph">
            <img 
                src={ChartPhoto} 
                alt="Description" 
                style={{ width: '80vw', height: 'auto' }}/>
            </div>
            
        </div>
    )
}