import '../InstanceStyles.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

//TODO: See if we can get more specific with google place ID
function InstanceLayout() {
    const location = useLocation()
    const id = location.pathname
    const id2 = id.split("/")[2]
    const [lineID, lineIDSet] = useState(null);
    const [stopID, stopIDSet] = useState(628);
    const [stopName, stopNameSet] = useState("None");
    const [lineName, lineNameSet] = useState("None");


    const baseUrl = `https://backend.nocarnoproblem.net/api/destinations/${id2}`;
  
    const [dest, destSet ] = useState([]);
    useEffect(() => {
        axios.get(baseUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        })
            .then((response) => {
                destSet(response.data.data.attributes);

                if (
                    response.data.data.relationships &&
                    response.data.data.relationships.stops &&
                    response.data.data.relationships.stops.data &&
                    response.data.data.relationships.stops.data.length > 0
                ) {
                    stopIDSet(response.data.data.relationships.stops.data[0].id);
                }
                if (
                    response.data.data.relationships &&
                    response.data.data.relationships.lines &&
                    response.data.data.relationships.lines.data &&
                    response.data.data.relationships.lines.data.length > 0
                ) {
                    lineIDSet(response.data.data.relationships.lines.data[0].id);
                }
            });
    }, [baseUrl]);

    const stopUrl = `https://backend.nocarnoproblem.net/api/stops/${stopID}`;
    const lineUrl = `https://backend.nocarnoproblem.net/api/lines/${lineID}`

    useEffect(() => {
        if(stopID){
        axios.get(stopUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            stopNameSet(response.data.data.attributes.stop_name);
        });
    }
    }, [stopID, stopUrl]);

    useEffect(() => {
        if(lineID){
        axios.get(lineUrl, {
            headers: {
                'Accept': 'application/vnd.api+json'
            }
        }).then((response) => {
            lineNameSet(response.data.data.attributes.route_long_name);
        });
    }
    }, [lineID, lineUrl]);

    if (!dest) {
        return null;
    }

    let linePath = `/lines/${lineID}`
    let stopPath = `/stops/${stopID}`
    let formattedHours = ""
    if(!dest.opening_hours){
        formattedHours = "Not Listed"
    } else{
        const days = dest.opening_hours
        formattedHours = days.map((day, index) => (
        <React.Fragment key={index}>
          {day}{index < days.length - 1 && <br/>} {/* Don't add <br> after the last item */}
        </React.Fragment>
      ));
    }
    
    return (
        <div>
            <Container>
                <Row style={{ height: 'calc(100vh-35px)' }}> {/* This ensures the row takes up the full height of the viewport */}
                    <Col md={6}>
                        {/* First half of the left column */}
                        <Row className="m-0 p-0 mb-2" style={{ height: 'calc((100vh - 35px - 1rem) / 2)' }}>
                            <Col className="m-0 p-0">
                                <img
                                    src={dest.photo_urls}
                                    alt="The Current Destination"
                                    className="img-fluid"
                                    style={{
                                        width: '100%',
                                        height: '370px', // adjusting for the border
                                        objectFit: 'cover',
                                        border: '2px solid black'
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Second half of the left column */}
                        <Row className="m-0 p-0" style={{ height: '50%' }}>
                            <Col className="m-0 p-0">
                                <iframe
                                //Change based on lat and lon
                                    src={dest.map_url}
                                    title='Map'
                                    alt = "Google Maps location of destination"
                                    width = '100%'
                                    height="450"
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 4px)',  // accounting for the border
                                        border: '2px solid black'
                                    }}
                                    allowFullScreen={true}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Col>
                        </Row>
                    </Col>
                    {/*Right Column */}
                    <Col md={6}>
                        <div class="card custom-card" style={{ width: '100%' }}>
                            <div class="card-body">
                                <h1 class="display-2"><strong> {dest.name} </strong></h1>
                                <ul class="list-group">
                                    <li class="list-group-item custom-item"><strong>Business Status:</strong> {dest.business_status || "Not Listed"}</li>
                                    <li class="list-group-item custom-item"><strong>Phone:</strong> {dest.phone || "Not Listed"}</li>
                                    <li class="list-group-item custom-item"><strong>Address:</strong> {dest.address || "Not Listed"}</li>
                                    <li class="list-group-item custom-item"><strong>Hours:</strong> {formattedHours}</li>
                                    <li className="list-group-item custom-item"><strong>Stop:</strong> <a class="btn btn-primary" href={stopPath} role="button"> {stopName}</a></li>
                                    <li className="list-group-item custom-item"><strong>Line:</strong> <a class="btn btn-primary" href={linePath} role="button"> {lineName}</a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default InstanceLayout;
